<template>
  <div>
    <label :class="disabled ? 'ctnrDis' : 'ctnr'"
      >{{ text }}
      <input type="checkbox" :checked="checked" @click="action" :disabled="disabled" />
      <span :class="disabled ? 'checkmarkDis' : 'checkmark'"></span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    id: { type: String },
    name: { type: String },
    disabled: { type: Boolean },
    text: { type: String },
    checked: { type: Boolean },
    changeCheck: { type: Function }
  },
  methods: {
    action() {
      if (!this.disabled) this.changeCheck(this.id, this.name)
    }
  }
}
</script>
<style lang="scss" scoped>
/* The container */
.ctnr {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  --tw-text-opacity: 1;
  font-size: 0.875rem; /* 14px */
  line-height: 1.45rem; /* 20px */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ctnr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  margin: 2px 0;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #888888;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.ctnr:hover input ~ .checkmark {
  background-color: rgb(243, 244, 246);
}

/* When the checkbox is checked, add a blue background */
.ctnr input:checked ~ .checkmark {
  background-color: #f7931e;
  border: 1px solid #f7931e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ctnr input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ctnr .checkmark:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ctnrDis {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: text;
  --tw-text-opacity: 1;
  font-size: 0.875rem; /* 14px */
  line-height: 1.45rem; /* 20px */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ctnrDis input {
  position: absolute;
  opacity: 0;
  cursor: text;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkDis {
  position: absolute;
  margin: 2px 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d6d6d6;
  background-color: #d6d6d6;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.ctnrDis input:checked ~ .checkmarkDis {
  background-color: #d6d6d6;
  border: 1px solid #d6d6d6;
}

/* Create the checkmarkDis/indicator (hidden when not checked) */
.checkmarkDis:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmarkDis when checked */
.ctnrDis input:checked ~ .checkmarkDis:after {
  display: block;
}

/* Style the checkmarkDis/indicator */
.ctnrDis .checkmarkDis:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
